import { createApp } from 'vue';
import App from './App.vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
import emailjs from 'emailjs-com';

emailjs.init('RMTPrrxEYVTSNt0J4'); // Replace 'YOUR_USER_ID' with your actual user ID from EmailJS

createApp(App).mount('#app');
