<template>
  <div class="parent">
    <div class="left">
      <img class="logo" src="@/assets/FTS - FULL- COLOUR.svg" alt="Logo" />
      <div class="promo-content">

        <div class="gap-top">
          <div class="promo-header">
            <hr class="promo-line" />
            <span class="promo-launch">LAUNCHING SOON</span>
          </div>
          <h1>Something exciting is about to happen</h1>
          <p class="content-text">
            Unlock exclusive hidden gems to elevate your content creation. Join
            our community and be the first to explore
            <span class="finding-slogan">
              Finding The <span class="spots">Spots</span>
            </span>.
          </p>
          <p class="notify">Get notified for early access</p>
          <form class="notify-form" @submit.prevent="sendEmail">
            <input type="email" class="input-email" placeholder="Fill in your email address" v-model="email" required />
            <button type="submit">SIGN UP</button>
          </form>
        </div>
        <div style="margin-bottom: 0; position: absolute; bottom: 18px;">
          <div class="border-divider"></div>
          <div class="contact-email">
            <span class="contact-text">If you need to reach us:</span>
            <span class="email-info">
              <i class="bi bi-envelope-fill email-icon"></i>
              info@findingthespots.com
            </span>
          </div>
          <div class="footer-text-desktop">
            ® Finding The Spots. Made in Amsterdam, NL
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <img class="right-image" src="@/assets/fts-full.jpg" alt="Image" />
      <div class="footer-text" style="padding-right: 72px;">® Finding The Spots. Made in Amsterdam, NL</div>
    </div>
  </div>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
  data() {
    return {
      email: ''
    };
  },
  methods: {
    async sendEmail() {
      if (!this.isValidEmail(this.email)) {
        alert('Please enter a valid email address.');
        return;
      }

      try {
        const response = await emailjs.send(
          process.env.VUE_APP_EMAILJS_SERVICE_ID,
          process.env.VUE_APP_EMAILJS_TEMPLATE_ID,
          {
            email: this.email,
            message: 'Sign up for early access'
          },
          process.env.VUE_APP_EMAILJS_USER_ID
        );
        alert('Email sent successfully', response);
        this.email = '';
      } catch (error) {
        console.error('Failed to send email', error);
        alert('There was an error sending the email. Please try again.');
      }
    },
    isValidEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    }
  }
};
</script>

<style>
.gap-top {
  margin-top: 22px;
}

.promo-line {
  width: 24px;
  border: none;
  border-top: 2px solid #ff6338;
  margin-right: 8px;
}

.promo-header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.email-icon {
  color: #ff6338;
  margin-right: 2px;
}

.contact-email {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.contact-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--colors-text-inactive, #999999);
}

.border-divider {
  border-top: 1.5px solid #4d4d4d;
  opacity: 0.2;
  margin-top: 50px;
}

.finding-slogan {
  font-weight: bold;
}

.spots {
  color: #ff6338;
}

.parent {
  display: flex;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.left {
  background-color: white;
  width: 50%;
  padding: 50px 100px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: auto;
}

.promo-content {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin-top: 50px;
}

.right {
  width: 50%;
  position: relative;
  overflow: hidden;
}

.right-image {
  width: 100%;
  height: 100%;
  border-radius: 0 0 0 40px;
  object-fit: cover;
}

.footer-text {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: white;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 5px;
}

.logo {
  width: 283px;
  height: 72.96px;
}

.promo-launch {
  display: inline-block;
  color: #000;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
}

.promo-content h1 {
  margin-bottom: 16px;
  font-size: 46px;
  font-weight: 700;
}

.content-text {
  margin-bottom: 32px;
  font-size: 20px;
  font-weight: 300;
}

.notify {
  margin-bottom: 8px;
  font-weight: bold;
}

.notify-form {
  display: flex;
  align-items: center;
}

.notify-form input {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px 0 0 4px;
  flex-grow: 1;
  outline: none;
}

.notify-form button {
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  background-color: #ff6338;
  color: white;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}

.input-email {
  margin-right: 6px;
  background-color: #f7f7f7;
}

@media (min-width: 769px) and (max-width: 1024px) {
  .left {
    padding: 50px;
  }
}

@media (min-width: 769px) {
  .footer-text-desktop {
    display: none;
  }
}

@media (max-width: 768px) {
  .parent {
    flex-direction: column;
  }

  .footer-text-desktop {
    margin-top: 20px;
    font-size: 14px;
    color: var(--colors-text-inactive, #999999);
    z-index: 1000;
  }

  .right {
    display: none;
  }

  .left {
    width: 100%;
    padding: 20px 32px;
  }

  .promo-content {
    max-width: 100%;
    margin-top: 48px;
  }

  .logo {
    width: 250px;
  }
}

@media (max-width: 992px) {
  .border-divider {
    margin-top: 200px;
  }
}

@media (max-width: 576px) {
  .border-divider {
    margin-top: 40px;
  }

  .left {
    padding: 20px 16px;
  }

  .promo-content h1 {
    font-size: 36px;
  }
}
</style>
